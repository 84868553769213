<template>
    <v-card variant="text">
        <v-img max-height="300" :src="pregunta.imagen" />
        <v-card-title  class="text-wrap" style="word-break: break-word">
            {{ pregunta.texto }}
        </v-card-title>
        <v-card-text>
            <v-textarea v-model="text"></v-textarea>
        </v-card-text>
    </v-card>
    <v-btn block v-if="!haRespondido" :disabled="loading" color="primary" class="mt-3" @click="enviarCuestionario">
        {{ t('Continua') }}
    </v-btn>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { axios } from '../axios'; 

const props = defineProps({
    pregunta: {
        type: Object,
        default: () => ({})
    }
});

const emits = defineEmits(['respuesta-enviada']);

const { t } = useI18n();
const text = ref();
const loading = ref(false);

async function enviarCuestionario() {
    const datosParaEnviar = {
        pregunta_id: props.pregunta.id,
        text: text.value
    };
    loading.value = true;
    try {
        const respuesta = await axios.post(`enviar_respuestas/`, datosParaEnviar);
        if (respuesta.data.success) {
            console.log('Respuesta:', respuesta.data.message);
        } else {
            console.error('Error al enviar respuestas:', respuesta.data.message);
        }
        loading.value = false;
    } catch (error) {
        console.error('Error al realizar la solicitud:', error);
        loading.value = false;
    } finally {
        next();
    }
}

function next() {
    emits('respuesta-enviada', props.pregunta);
    text.value = [];
}

// Inicializar las respuestas seleccionadas con respuestas previas del usuario
onMounted(() => {
    if (props.pregunta.respuestas_usuario && props.pregunta.respuestas_usuario.length > 0) {
        text.value = props.pregunta.respuestas_usuario[0].respuesta_texto
    }
});
</script>