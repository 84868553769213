<template>
    <v-card class="mx-4 mt-4">
        <v-data-table-virtual :headers="headers" :items="cuestionario.preguntas" :search="search" :loading="loading"
            @click:row="editPregunta">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ t('Preguntas') }}</v-toolbar-title>
                    <v-btn color="primary" @click="newPregunta" prepend-icon="mdi-plus">
                        {{ t('Añadir') }} {{ t('pregunta') }}
                    </v-btn>
                    <v-btn color="primary" @click="$emit('detalles-cuestionario')" prepend-icon="mdi-cog">
                        {{ t('cuestionario') }}
                    </v-btn>
                    <v-btn icon @click="closePreguntas">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-text-field class="mx-4 mt-4" v-model="search" label="Cerca" prepend-inner-icon="mdi-magnify"
                    single-line variant="outlined" hide-details></v-text-field>
            </template>
            <template v-slot:[`item.dificultad`]="{ value }">
                <v-chip :color="getColor(value)">
                    {{ value }}
                </v-chip>
            </template>
            <template v-slot:loading>
                <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
            </template>
        </v-data-table-virtual>
    </v-card>
    <v-dialog v-model="editDialog" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ t('Editar') }} {{ t('pregunta') }}</v-toolbar-title>
                <v-btn color="primary" @click="detallsPregunta" prepend-icon="mdi-cog">
                    {{ t('pregunta') }}
                </v-btn>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field disabled v-model="editedPregunta.texto" :label="t('Pregunta')"></v-text-field>
                <v-select disabled v-model="editedPregunta.tipo" :items="tiposPreguntas" :label="t('Tipo')"></v-select>
                <v-select disabled v-model="editedPregunta.dificultad" :items="nivelesDificultad"
                    :label="t('Dificultad')"></v-select>
                <!-- Verifica si hay una URL de imagen y muestra el thumbnail -->
                <v-img v-if="editedPregunta.imagen" :src="editedPregunta.imagen_thub" height="100" />
                <v-data-table :headers="headersOp" :items="editedPregunta.opciones" @click:row="editOpcion"
                    hide-default-footer>
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ t('Opciones') }}</v-toolbar-title>
                            <v-btn color="primary" @click="newOpcion">
                                <v-icon>mdi-plus</v-icon>
                                {{ t('Añadir') }} {{ t('opción') }}
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.es_correcta`]="{ item }">
                        <v-checkbox v-model="item.es_correcta" readonly></v-checkbox>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="crearPDialog" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ t('Crear') }} {{ t('pregunta') }}</v-toolbar-title>
                <v-btn color="primary" text @click="save">{{ t('Guardar') }}</v-btn>
                <v-btn icon @click="closePDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-text-field v-model="editedPregunta.texto" :label="t('Pregunta')"></v-text-field>
                <v-select v-model="editedPregunta.tipo" :items="tiposPreguntas" :label="t('Tipo')"></v-select>
                <v-select v-model="editedPregunta.dificultad" :items="nivelesDificultad"
                    :label="t('Dificultad')"></v-select>
                <v-file-input v-model="editedPregunta.imagen" :label="t('Imagen')" prepend-icon="mdi-camera" accept="image/*"
                    @change="handleFileUpload"></v-file-input>
                <!-- Verifica si hay una URL de imagen y muestra el thumbnail -->
                <v-img v-if="editedPregunta.imagen" :src="editedPregunta.imagen_thub" height="100" />
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="editOpcionDialog" persistent>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ t('Editar') }} {{ t('opción') }}</v-toolbar-title>
                <v-btn color="primary" text @click="saveOp">{{ t('Guardar') }}</v-btn>
                <v-btn icon @click="closeOpciones">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <HelperCarregant v-if="loading" :missatge="t('Actualizando') + ' ' + t('opción')" />
                <template v-if="!loading">
                    <v-text-field v-model="editedOpcion.texto" :label="t('Opción')"></v-text-field>
                    <v-checkbox v-model="editedOpcion.es_correcta" :label="t('Es_correcta')"></v-checkbox>
                    <v-text-field v-model="editedOpcion.texto2" :label="t('Relación')"></v-text-field>
                    <v-text-field v-model="editedOpcion.orden" :label="t('Orden')"></v-text-field>
                    <v-text-field v-model="editedOpcion.motivo" :label="t('Explicación')"></v-text-field>
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import HelperCarregant from './HelperCarregant.vue';
import { axios } from '../axios';
import { useI18n } from 'vue-i18n';
export default {
    emits: ['actualizar-preguntas'],
    props: {
        cuestionario: {
            type: Object,
            default: () => ({})
        },
        loading: Boolean,
    },
    components: {
        HelperCarregant,
    },
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    },
    data() {
        return {
            headers: [
                { title: this.t('ID'), key: 'id' },
                { title: this.t('Pregunta'), key: 'texto' },
                { title: this.t('Tipo'), key: 'tipo' },
                { title: this.t('Opciones'), key: 'opciones.length' },
                { title: this.t('Dificultad'), key: 'dificultad' },
            ],
            headersOp: [
                { title: this.t('ID'), key: 'id' },
                { title: this.t('Texto'), key: 'texto' },
                { title: this.t('Relación'), key: 'texto2' },
                { title: this.t('Orden'), key: 'orden' },
                { title: this.t('Es_correcta'), key: 'es_correcta' },
                { title: this.t('Explicación'), key: 'motivo' },
            ],
            editDialog: false,
            crearPDialog: false,
            editOpcionDialog: false,
            editedPregunta: {},
            editedOpcion: {},
            search: '',
            tiposPreguntas: ['multiple_choice', 'single_choice', 'relacionar', 'relacionar_cols', 'ordenar', 'texto'],
            nivelesDificultad: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
            loading2: false,
            snackbarText: '',
        }
    },
    methods: {
        getColor(dificultad) {
            if (dificultad > 7) return 'red'
            else if (dificultad > 4) return 'orange'
            else return 'green'
        },
        newPregunta() {
            this.editedPregunta = {
                id: null,
                cuestionario: this.cuestionario.id,
                texto: '',
                imagen: null,
                imagen_thub: null,
                tipo: 'multiple_choice',
                opciones: [],
                dificultad: 5
            };
            // Abre el diálogo para editar/crear una nueva pregunta
            this.crearPDialog = true;

        },
        editPregunta(e, { item }) {
            this.editedPregunta = Object.assign({}, item);
            this.editedPregunta.cuestionario = this.cuestionario.id;
            this.editedPregunta.imagen_thub = this.editedPregunta.imagen;
            this.editDialog = true;
        },
        detallsPregunta() {
            this.crearPDialog = true;
        },
        newOpcion() {
            this.editedOpcion = {
                id: null,
                pregunta: this.editedPregunta.id,
                texto: '',
                texto2: '',
                es_correcta: false,
                orden: 0,
                motivo: '',
            };
            // Abre el diálogo para editar/crear una nueva pregunta
            this.editOpcionDialog = true;

        },
        editOpcion(e, { item }) {
            this.editedOpcion = Object.assign({}, item);
            this.editedOpcion.pregunta = this.editedPregunta.id;
            this.editOpcionDialog = true;
        },
        handleFileUpload(event) {
            if (event.target.files && event.target.files.length > 0) {
                this.editedPregunta.imagen = event.target.files[0]; // Asegura que estamos tomando el primer archivo

                const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.editedPregunta.imagen_thub = e.target.result; // Esto mostrará el thumbnail de la nueva imagen
                    };
                    reader.readAsDataURL(file);
                }
            } else {
                this.editedPregunta.imagen = null; // Asegura limpiar el campo si no hay archivo
            }
        },
        async save() {
            this.loading2 = true;
            const formData = new FormData();
            Object.keys(this.editedPregunta).forEach(key => {
                const value = this.editedPregunta[key];
                // Comprueba si el valor no es null antes de agregarlo al FormData
                if (value !== null && value !== undefined) {
                    if (key === 'imagen' && value instanceof File) {
                        formData.append(key, value);
                    } else {
                        formData.append(key, value);
                    }
                }
            });
            try {
                if (this.editedPregunta.id) {
                    await axios.put(`preguntas/${this.editedPregunta.id}/`, formData);
                    this.$store.dispatch('triggerSnackbar', { message: this.t("actualizado_con_éxito"), color: 'success' });
                } else {
                    await axios.post('preguntas/', formData);
                    this.$store.dispatch('triggerSnackbar', { message: this.t("creado_con_éxito"), color: 'success' });
                }
                this.$emit('actualizar-preguntas');
                this.editDialog = false;
                this.loading2 = false;
                this.crearPDialog = false;
            } catch (error) {
                if (error.response && error.response.data && typeof error.response.data === 'object') {
                    // Extraemos los mensajes de error del cuerpo de la respuesta
                    const errors = error.response.data;
                    let errorMessage = '';
                    // Recorremos cada clave del objeto de errores para construir el mensaje
                    Object.keys(errors).forEach(key => {
                        errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join para convertir posibles arrays en strings
                    });
                    this.snackbarText = errorMessage;
                } else {
                    // Si la respuesta de error no es en el formato esperado
                    this.snackbarText = error;
                }
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
                this.loading2 = false;
            }
        },
        async saveOp() {
            this.loading2 = true;
            const index_p = this.cuestionario.preguntas.findIndex(m => m.id === this.editedOpcion.pregunta);
            const index_o = this.cuestionario.preguntas[index_p].opciones.findIndex(m => m.id === this.editedOpcion.id);
            try {
                if (this.editedOpcion.id) {
                    const response = await axios.put(`opciones/${this.editedOpcion.id}/`, this.editedOpcion);
                    this.editedPregunta.opciones.splice(index_o, 1, response.data);
                    this.$store.dispatch('triggerSnackbar', { message: this.t("actualizado_con_éxito"), color: 'success' });
                } else {
                    const response = await axios.post('opciones/', this.editedOpcion);
                    this.editedPregunta.opciones.push(response.data);
                    this.$store.dispatch('triggerSnackbar', { message: this.t("creado_con_éxito"), color: 'success' });
                }
                this.$emit('actualizar-preguntas');
                this.editOpcionDialog = false;
                this.loading2 = false;
            } catch (error) {
                if (error.response && error.response.data && typeof error.response.data === 'object') {
                    // Extraemos los mensajes de error del cuerpo de la respuesta
                    const errors = error.response.data;
                    let errorMessage = '';
                    // Recorremos cada clave del objeto de errores para construir el mensaje
                    Object.keys(errors).forEach(key => {
                        errorMessage += `${key}: ${errors[key].join(' ')}\n`; // Join para convertir posibles arrays en strings
                    });
                    this.snackbarText = errorMessage;
                } else {
                    // Si la respuesta de error no es en el formato esperado
                    this.snackbarText = error;
                }
                this.$store.dispatch('triggerSnackbar', { message: this.snackbarText, color: 'error' });
                this.loading2 = false;
            }
        },
        close() {
            this.editDialog = false;
        },
        closePDialog() {
            this.crearPDialog = false;
        },
        closePreguntas() {
            this.$emit('cerrar-preguntas');
        },
        closeOpciones() {
            this.editOpcionDialog = false;
        }
    },
}
</script>